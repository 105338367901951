import { Link } from "gatsby"
import { t } from "i18next"
import _ from "lodash"
import React from "react"
import ArrowRight from "../../../../assets/icons/seasongroup_icons_arrow-right.svg"
import { useTranslation } from "react-i18next"

const CardOpenings = ({ data }) => {
  const location = _.get(data, "location[0].name")

  const { i18n } = useTranslation();

  const handleClick = url => {
    // Save the current scroll position to localStorage
    localStorage.setItem("scrollPosition", window.scrollY.toString())
    // Navigate to the target URL
    window.location.href = url
  }

  return (
    <div className="row cardOpening__container">
      <div className="col-md-4 col-12">
        <span className="cardOpening__location">{location}</span>
        <h4 className="cardOpening__position">{data.title}</h4>
        <span className="cardOpening__date">{data.human_date}</span>
      </div>
      <div className="col-md-8 col-12">
        <div
          className="rich-text"
          dangerouslySetInnerHTML={{ __html: data.excerpt }}
        />
        <a
          href={`${i18n.language === 'en' ? '' : `/${i18n.language}`}/careers/${data.slug}`}
          className="cardOpening__link"
          onClick={e => {
            e.preventDefault()
            handleClick(`${i18n.language === 'en' ? '' : `/${i18n.language}`}/careers/${data.slug}`)
          }}
        >
          <span className="cardOpening__more">{t("careers.more")}</span>
          <ArrowRight width="17" fill="#018AFF" />
        </a>
      </div>
    </div>
  )
}

export default CardOpenings
