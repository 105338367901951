import _ from 'lodash'
import { navigate } from 'gatsby'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Spinner } from '../../components'
import GradientBanner from '../../components/gradient-banner'
import './careers-details-styles.scss'
import IconBack from '../../assets/icons/seasongroup_icons_arrow-right.svg'
import IconPacked from '../../assets/icons/seasongroup_icons_email.svg'

const CareersDetailView = ({
  data,
  loading,
}) => {
  const { t } = useTranslation()

  const goBack = () => {
    navigate(-1)
  }
  if (loading) {
    return <Spinner loading={loading} />
  }

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="careersDetail__container">
          <div className="careersDetail__button">
            <p onClick={goBack}><IconBack fill="#018AFF" width="15" /> <span>{t('careers.details.back')}</span></p>
          </div>
          <div className="careersDetail__title">
            <h1 className="page-title">{data.title}</h1>

            <div className="careersDetail__infos">
              <div className="careersDetail__info">
                <span>{t('careers.details.locations')}</span>
                <p>{_.get(data, 'location[0].name')}</p>
              </div>
              <div className="careersDetail__info">
                <span>{t('careers.details.date')}</span>
                <p>{data.human_date}</p>
              </div>
            </div>
            <div className="careersDetail__content">
              <div className="rich-text" dangerouslySetInnerHTML={{ __html: data.content }} />
            </div>
            <div className="careersDetail__cta">
              <p>{_.get(data, 'acf.apply_info.copy')}</p>
              <a href={`mailto:${_.get(data, 'acf.apply_info.email')}`} target="_blank" rel="noopener noreferrer">
                <IconPacked width="15" fill="#018AFF"/><span>{_.get(data, 'acf.apply_info.email')}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <GradientBanner />
    </React.Fragment>
  )
}

export default CareersDetailView
