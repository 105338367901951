import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {

  TitleTextBlock,
} from '../../../../components'

const HeaderCareers = ({ data, t }) => {
	const { i18n } = useTranslation();
	return (
		<div className="careers__header__container">
			<div className="careers__header__head">
			<TitleTextBlock
				layout={data}
				text={t('tagline.carrers')}
				descr={data.copy}>
				<Trans i18nKey='careers.title'>
				Our people <span className='title-color'>{{ love: i18n.language !== 'en' ? '热爱' : 'love' }}</span> what they do
				</Trans>
			</TitleTextBlock>

			</div>

		</div>
		)
}

export default HeaderCareers
