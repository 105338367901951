import _ from "lodash"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useOpening } from "../../../../hooks/use-openings-careers"
import { getOptionsSelect } from "../../../../helpers"
import Select from "../../../../components/select"
import CardOpenins from "../cart-openings"
const List = ({ openings }) =>
  _.map(openings, (opening, idx) => (
    <div className="col-12" key={opening.id + opening.title}>
      <CardOpenins data={opening} />
    </div>
  ))

const CareersOpening = () => {
  const {
    locations,
    openings,
    onHandelLocations,
    onHandleNextPage,
    pageSelect,
    totalPosts,
    isLoading,
  } = useOpening()

  const { t } = useTranslation()
  const selectOptions = getOptionsSelect(locations, t("news.all-locations"))

  useEffect(() => {
    // Retrieve the saved scroll position from localStorage
    const savedScrollPosition = localStorage.getItem("scrollPosition")

    if (savedScrollPosition) {
      // Scroll to the saved position
      window.scrollTo(0, parseInt(savedScrollPosition))
      // Clear the saved scroll position
      localStorage.removeItem("scrollPosition")
    }
  }, [])

  return (
    <div className="careersOpenning__container">
      <div className="careersOpenning__head">
        <div className="careersOpenning__title">
          <h2>{t("careers.titleOpenings")}</h2>
        </div>
        <div className="careersOpenning__filters__box">
          <Select
            // isClearable={true}
            options={selectOptions}
            onChange={onHandelLocations}
            placeholder={t("careers.filters.placeholder")}
          />
        </div>
      </div>
      <div className="careersOpenning__list">
        <div className="row">
          {isLoading ? (
            <></>
          ) : openings.length === 0 ? (
            <p className="search__noResults">{t("search.noResults")}</p>
          ) : (
            <List openings={openings} />
          )}
        </div>
        {!isLoading && openings.length < totalPosts && (
          <div className="row">
            <div className="col-12 button">
              <div
                className="careersOpenning__button"
                onClick={() => onHandleNextPage(pageSelect + 1)}
              >
                <p>
                  <span>
                    {t("ui.buttons.loadMore", {
                      left:
                        totalPosts - openings.length < 8
                          ? totalPosts - openings.length
                          : 8,
                      items: totalPosts - openings.length,
                    })}
                  </span>
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default CareersOpening
