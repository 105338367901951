import _ from "lodash"
import React from "react"
import { useTranslation } from "react-i18next"
import { Spinner, GradientBanner, Seo } from "../../components"
import FramePictures from "./components/frame-pictures"
import { CareerOpening, HeaderCareers, TwoColumns } from "./components"
import "./careers-styles.scss"

const CareersView = ({ loading, data }) => {
  const { t } = useTranslation()
  if (!_.get(data, "acf.rows")) {
    return <Spinner loading={true} />
  }
  const sections = _.get(data, "acf.rows")
  return (
    <div className="careers container-fluid">
      <Seo title="Careers" />
      <div className="careers__container">
        <div className="careers__layout">
          <HeaderCareers data={sections[0]} t={t} />
        </div>
        <div className="careers__pictures">
          <FramePictures data={sections[0]} />
        </div>
        <div className="careers__layout">
          <TwoColumns data={sections[1]} />
        </div>
      </div>
      <div className="careers__background"></div>
      <div className="careers__container">
        <CareerOpening />
      </div>
      <GradientBanner />
    </div>
  )
}

export default CareersView
